/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import Dropzone from 'react-dropzone'
import { isNumber } from 'util'
import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import FormInput from '../../components/formInput'
import { CONST } from '../../constants/index'
import { addBlankChoices } from '../../utils/utilFunctions'

const uuidv4 = require('uuid/v4')
// TODO school year SY19-20 for text like applying for ...
class TuitionAssistance extends Component {
  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(window.location.search)
    const key = urlParams.get('key')

    this.state = {
      ta_enabled: false,
      admission_enabled: false,
      fetchedConfig: false,
      key: key,

      localKey: `tuitionAssistanceFields${props.branch === 'Bangkok' ? '' : props.branch}`,
      fields: {
        branch: props.branch,
        school_year: '',
        signature_father: '',
        signature_mother: '',
        signature_guardian: '',
        signature_date: '',
        accepted_pdpa: false,

        father_first_name: '',
        father_last_name: '',
        mother_first_name: '',
        mother_last_name: '',

        mobile: '',
        email: '',
        address: '',
        marital_status: '',

        father_nationality: '',
        father_country: '',
        father_employer: '',
        father_job_title: '',
        father_type_of_visa: '',

        mother_nationality: '',
        mother_country: '',
        mother_employer: '',
        mother_job_title: '',
        mother_type_of_visa: '',

        live_with_children: '',
        live_with_children_detail: '',
        years_enrolled: '',
        years_received_ta: '',
        ta_children: [
          {
            name: '',
            grade: '',
          },
        ],
        ta_college_children: [
          {
            name: '',
            age: '',
            college: '',
          },
        ],
        ta_other_children: [
          {
            name: '',
            age: '',
            grade: '',
            school: '',
          },
        ],

        actual_father_income: '',
        actual_mother_income: '',
        actual_interest_income: '',
        actual_rental_income: '',
        actual_other_income: '',
        actual_total_income: '',

        estimated_father_income: '',
        estimated_mother_income: '',
        estimated_interest_income: '',
        estimated_rental_income: '',
        estimated_other_income: '',
        estimated_total_income: '',

        residence_allowance: '',
        residence_allowance_only: false,
        vehicle_allowance: '',
        vehicle_allowance_only: false,
        educational_allowance: '',
        educational_allowance_only: false,
        other_allowance: '',
        other_allowance_explain: '',
        th_savings: '',
        th_investments: '',
        th_properties: '',
        th_other: '',
        th_other_explain: '',
        oc_savings_investment: '',
        oc_properties: '',
        oc_other: '',
        oc_other_explain: '',
        debt_housing: '',
        debt_vehicle: '',
        debt_business: '',
        debt_other: '',
        debt_other_explain: '',
        ta_percentage: '',
        expect_next_year: '',
        payment_plan: '',
        denied_fill: false,
        denied_loan: false,
        denied_withdraw: false,
        denied_other_explain: '',
        ta_attachment: [],
      },

      errors: {},
    }
  }

  async componentDidMount() {
    const res = await this.props.context.fetch('get', `/api/config`)
    if (res.success) {
      console.log(res.data)
      this.setState({
        ta_enabled: res.data.ta_enabled,
        admission_enabled: res.data.admission_enabled,
        fetchedConfig: true,
      })
    } else {
      alert(`Unable to fetch config: ${res.data}`)
    }

    document.title = 'Tuition Assistance Application'

    const storedFields = localStorage.getItem(this.state.localKey)
    const currentFieldsObj = this.state.fields
    if (storedFields) {
      try {
        const storedFieldsObj = JSON.parse(storedFields)
        // in case user have empty list, merging with not create "default" row
        currentFieldsObj.ta_children = []
        currentFieldsObj.ta_college_children = []
        currentFieldsObj.ta_other_children = []
        this.setState({ fields: _.merge(currentFieldsObj, storedFieldsObj) })
      } catch {}
    }
  }

  submit = async () => {
    // TODO failsafe when API server is down
    const fields = _.cloneDeep(this.state.fields)
    fields.ta_attachment = fields.ta_attachment.filter(a => a.id !== undefined).map(a => a.id)
    fields.ta_children = fields.ta_children.filter(c => c.name !== '')
    fields.ta_college_children = fields.ta_college_children.filter(c => c.name !== '')
    fields.ta_other_children = fields.ta_other_children.filter(c => c.name !== '')

    const res = await this.props.context.fetch('post', `/api/ta/`, fields)
    if (res.success) {
      localStorage.removeItem(this.state.localKey)
      this.props.history.push(`/submitted?b=${this.props.branch}&origin=ta`)
    } else {
      toast.error('Some fields are not valid. Please fix all errors and resubmit')
      this.setState({ errors: res.data })
    }
  }

  setStateThenSaveLocal = state => {
    this.setState(state, this.saveFieldsToLocalStorage)
  }

  saveFieldsToLocalStorage = _.debounce(() => {
    localStorage.setItem(this.state.localKey, JSON.stringify(this.state.fields))
  }, 1000)

  handleFileUpload = async (file, filetype) => {
    const formData = new FormData()
    formData.append('url', file)
    formData.append('filename', file.name)
    formData.append('filetype', filetype)

    const localId = uuidv4()
    const attachments = _.cloneDeep(this.state.fields.ta_attachment)
    attachments.push({ localId, name: file.name, uploaded: false, filetype })
    this.setStateThenSaveLocal({
      fields: { ...this.state.fields, ta_attachment: attachments },
    })

    // config for rendering upload percent
    const config = {
      onUploadProgress: progressEvent => {
        let attachments = _.cloneDeep(this.state.fields.ta_attachment)
        attachments = attachments.map(a => {
          if (a.localId === localId) {
            return { ...a, progress: Math.round((progressEvent.loaded * 100) / progressEvent.total) }
          }
          return a
        })
        this.setStateThenSaveLocal({
          fields: { ...this.state.fields, ta_attachment: attachments },
        })
      },
    }

    const res = await this.props.context.fetch('post', '/api/ta-attachment/', formData, undefined, config)
    if (res.success) {
      let attachments = _.cloneDeep(this.state.fields.ta_attachment)
      attachments = attachments.map(a => {
        if (a.localId === localId) {
          return { ...a, id: res.data.id, url: res.data.url.replace('http://127.0.0.1:8000', process.env.REACT_APP_SERVER_ADDR), uploaded: true }
        }
        return a
      })
      this.setStateThenSaveLocal({
        fields: { ...this.state.fields, ta_attachment: attachments },
      })
    } else {
      let errorMsg = ''
      if (res.data !== undefined && res.data.url !== undefined) {
        errorMsg = res.data.url.join('\n')
      } else if (res.data === undefined) {
        errorMsg = 'Please check your connection and try again'
      } else {
        errorMsg = `Unknown error - ${JSON.stringify(res.data)}`
      }
      toast.error(`File upload error: ${errorMsg}`)
      const newAttachment = _.cloneDeep(this.state.fields.admission_attachment.filter(a => a.localId !== localId))
      this.setStateThenSaveLocal({
        fields: { ...this.state.fields, admission_attachment: newAttachment },
      })
    }
  }

  renderDropzone = (fileTypeName, fieldName) => {
    return (
      <Dropzone
        onDrop={acceptedFiles => {
          acceptedFiles.map(file => this.handleFileUpload(file, fileTypeName))
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="dropzone d-flex align-items-center justify-content-center">
              <input {...getInputProps()} />
              <div className="text-center">
                <div>{fieldName}</div>
                <div className="drop-zone-hint">
                  <div>Drop files here or click to select files</div>
                  <div>Max file size: 10MB</div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    )
  }

  renderDroppedFiles = filetype => {
    const { fields } = this.state
    return (
      <div>
        {fields.ta_attachment
          .filter(a => a.filetype === filetype)
          .map((attachment, idx) => {
            const { progress, uploaded, name, localId } = attachment
            let uploadStatus = ''
            if (uploaded === true) {
              uploadStatus = 'uploaded'
            } else if (isNumber(progress)) {
              uploadStatus = `uploading ${progress}%`
            }
            // DELETE SERVERSIDE
            return (
              <div key={localId} className="dropped-files">
                <a target="_newtab" href={attachment.url}>{`${name} - ${uploadStatus}`}</a>
                <span
                  className="a ml-1 deletespan"
                  onClick={() => {
                    const newAttachment = _.cloneDeep(fields.ta_attachment.filter(a => a.localId !== localId))
                    this.setStateThenSaveLocal({
                      fields: { ...fields, ta_attachment: newAttachment },
                    })
                  }}
                >
                  (Delete)
                </span>
              </div>
            )
          })}
      </div>
    )
  }

  renderFields = (objectKeyList, args) => {
    // TODO: shouldn't declare new var?
    const { fields, errors } = this.state
    let fValue = fields
    let eValue = errors
    objectKeyList.forEach(key => {
      fValue = fValue !== undefined && fValue[key] !== undefined ? fValue[key] : ''
      eValue = (eValue && eValue[key]) || undefined
    })
    return (
      <FormInput
        {...args}
        value={fValue}
        errors={eValue}
        onChange={v => {
          const newFields = _.cloneDeep(this.state.fields)
          let ac = newFields
          objectKeyList.forEach((key, idx) => {
            if (idx !== objectKeyList.length - 1) {
              ac = ac[key]
            } else {
              ac[key] = v
            }
          })
          // console.log(newFields)
          this.setStateThenSaveLocal({ fields: newFields })
        }}
      />
    )
  }

  // TODO Check of admissions need this too?
  // render some year which changes depending on dropdown
  renderYear = isFlatYear => {
    const sy = this.state.fields.school_year
    if (sy === '') return isFlatYear ? '2024' : 'SY24-25' // when user haven't select year
    const const_item = CONST.school_year.find(item => item.value === sy)
    if (const_item === undefined){ // happens earlier when school year enum is 1 / 2 (removed from const)
      return "-"
    }
    const selectedYear = parseInt(const_item.label.slice(-5, -3))
    if (!const_item) return isFlatYear ? `20${selectedYear}` : `SY${selectedYear}-${selectedYear + 1}`
    return isFlatYear ? `20${selectedYear}` : const_item.label
  }

  render() {
    const { fields, errors, ta_enabled, fetchedConfig } = this.state
    const { renderFields } = this

    if (this.state.key !== '40OZHwLAuB') {
      return (
        <div className="p-2">
          <h1 style={{ border: 'none' }}>Not Found</h1>
          <h3>The requested URL {this.props.location.pathname} was not found</h3>
        </div>
      )
    }
    if (!fetchedConfig) {
      return <Layout title={`Tuition Assistance Application - ${this.props.branch}`} branch={this.props.branch} />
    }
    if (!ta_enabled) {
      return (
        <Layout title={`Tuition Assistance Application - ${this.props.branch}`} branch={this.props.branch}>
          <h1 className="my-5 text-center" style={{ border: 'none' }}>
            Tuition assistance application is closed
          </h1>
        </Layout>
      )
    }

    return (
      <Layout title={`Tuition Assistance Application - ${this.props.branch}`} branch={this.props.branch}>
        <form>
          <div className="row">
            <div className="col-12 col-md-6">{renderFields(['school_year'], { fieldName: 'Apply for school year: ', choices: CONST.school_year.filter(sy => sy.value > 6 && sy.value < 8) })}</div>
            {/* <div className="col-12 col-md-6">{renderFields(['school_year'], { fieldName: 'Apply for school year: ', choices: CONST.school_year.filter(sy => sy.value > 1 && sy.value !== 4) })}</div> */}
          </div>
          <hr />
          <h1>Personal Information</h1>
          <div className="row">
            <h5 className="mt-3 col-12">1. Parent or Guardian</h5>
            <div className="col-2 field-label">Father</div>
            <div className="col-5 px-2">{renderFields(['father_last_name'], { fieldName: 'Last Name' })}</div>
            <div className="col-5 px-2">{renderFields(['father_first_name'], { fieldName: 'First Name' })}</div>
            <div className="col-2 field-label">Mother</div>
            <div className="col-5 px-2">{renderFields(['mother_last_name'], { fieldName: 'Last Name' })}</div>
            <div className="col-5 px-2">{renderFields(['mother_first_name'], { fieldName: 'First Name' })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">2. Contact Information</h5>
            <div className="col-12 col-md-6">{renderFields(['mobile'], { fieldName: 'Mobile' })}</div>
            <div className="col-12 col-md-6">{renderFields(['email'], { fieldName: 'Email' })}</div>
            <div className="col-12">{renderFields(['address'], { fieldName: 'Address' })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">3. Current Marital Status</h5>
            <div className="col-12">{renderFields(['marital_status'], { radioChoices: CONST.marital_status, className: 'form-check-inline' })}</div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <h5 className="mt-3 col-4 offset-4 text-center">Father</h5>
                <h5 className="mt-3 col-4 text-center">Mother</h5>
                <h5 className="mt-3 col-4">4. Nationality</h5>
                <div className="col-4">{renderFields(['father_nationality'], { choices: addBlankChoices(CONST.country) })}</div>
                <div className="col-4">{renderFields(['mother_nationality'], { choices: addBlankChoices(CONST.country) })}</div>
                <h5 className="mt-3 col-4">5. Country of Passport</h5>
                <div className="col-4">{renderFields(['father_country'], { choices: addBlankChoices(CONST.country) })}</div>
                <div className="col-4">{renderFields(['mother_country'], { choices: addBlankChoices(CONST.country) })}</div>
                <h5 className="mt-3 col-4">6. Employer</h5>
                <div className="col-4">{renderFields(['father_employer'])}</div>
                <div className="col-4">{renderFields(['mother_employer'])}</div>
                <h5 className="mt-3 col-4">7. Job Title</h5>
                <div className="col-4">{renderFields(['father_job_title'])}</div>
                <div className="col-4">{renderFields(['mother_job_title'])}</div>
                <h5 className="mt-3 col-4">8. Type of visa</h5>
                <div className="col-4">{renderFields(['father_type_of_visa'], { choices: addBlankChoices(CONST.type_of_visa) })}</div>
                <div className="col-4">{renderFields(['mother_type_of_visa'], { choices: addBlankChoices(CONST.type_of_visa) })}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">9. If married, do both father and mother live together with children during school year?</h5>
            <div className="col-12">{renderFields(['live_with_children'], { radioChoices: CONST.live_with_children })}</div>
            <div className="col-12">{renderFields(['live_with_children_detail'], { fieldName: 'Additional detail, if needed:' })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12 d-flex col-form-inline">10. Number of years you have had at least one child enrolled at ICS: {renderFields(['years_enrolled'])} years</h5>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12 d-flex col-form-inline">11. Number of years you have received tuition assistance for at least one child at ICS: {renderFields(['years_received_ta'])} years</h5>
          </div>
          <div className="row mb-3">
            <h5 className="mt-3 col-12">12. List the children to be considered for tuition assistance</h5>
            <div className="col-12">
              <div className="d-none d-md-flex row">
                <div className="col-5">Name</div>
                <div className="col-5">{`Grade in ${this.renderYear(false)}`}</div>
                <div className="col-2" />
                <div className="col-12" />
              </div>
              {fields.ta_children.map((p, idx) => (
                <div key={idx} className="row">
                  <div className="col-12 col-md-5">{renderFields(['ta_children', idx, 'name'], { fieldName: 'Name', labelClassName: 'd-md-none' })}</div>
                  <div className="col-12 col-md-5">{renderFields(['ta_children', idx, 'grade'], { fieldName: 'Grade', choices: CONST.grades, labelClassName: 'd-md-none' })}</div>
                  <div className="col-12 col-md-2">
                    {idx !== 0 ? (
                      <button
                        type="button"
                        className="btn btn-info btn-form"
                        onClick={() => {
                          const newTaChildren = _.cloneDeep(fields.ta_children)
                          newTaChildren.splice(idx, 1)
                          this.setStateThenSaveLocal({
                            fields: { ...fields, ta_children: newTaChildren },
                          })
                        }}
                      >
                        Delete
                      </button>
                    ) : null}
                    <hr className="d-md-none" />
                  </div>
                </div>
              ))}
              {fields.ta_children.length < 6 ? (
                <button
                  type="button"
                  className="btn btn-info btn-form"
                  onClick={() => {
                    const newTaChildren = _.cloneDeep(fields.ta_children)
                    newTaChildren.push({
                      name: '',
                      grade: '',
                    })
                    this.setStateThenSaveLocal({
                      fields: { ...fields, ta_children: newTaChildren },
                    })
                  }}
                >
                  Add
                </button>
              ) : null}
              <hr className="d-md-none" />
            </div>
          </div>
          <div className="row mb-3">
            <h5 className="mt-3 col-12">13. List any children graduating or currently in college/university</h5>
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="d-none d-md-flex row">
                    <div className="col-4">Name</div>
                    <div className="col-2">Age</div>
                    <div className="col-5">College Attending</div>
                    <div className="col-1" />
                  </div>
                  {fields.ta_college_children.map((p, idx) => (
                    <div key={idx} className="row">
                      <div className="col-12 col-md-4">{renderFields(['ta_college_children', idx, 'name'], { fieldName: 'Name', labelClassName: 'd-md-none' })}</div>
                      <div className="col-12 col-md-2">{renderFields(['ta_college_children', idx, 'age'], { fieldName: 'Age', labelClassName: 'd-md-none' })}</div>
                      <div className="col-12 col-md-5">{renderFields(['ta_college_children', idx, 'college'], { fieldName: 'College', labelClassName: 'd-md-none' })}</div>
                      <div className="col-12 col-md-1">
                        {idx !== 0 ? (
                          <button
                            type="button"
                            className="btn btn-info btn-form"
                            onClick={() => {
                              const newTaCollegeChildren = _.cloneDeep(fields.ta_college_children)
                              newTaCollegeChildren.splice(idx, 1)
                              this.setStateThenSaveLocal({
                                fields: { ...fields, ta_college_children: newTaCollegeChildren },
                              })
                            }}
                          >
                            Delete
                          </button>
                        ) : null}
                        <hr className="d-md-none" />
                      </div>
                    </div>
                  ))}
                  {fields.ta_college_children.length < 6 ? (
                    <button
                      type="button"
                      className="btn btn-info btn-form"
                      onClick={() => {
                        const newTaCollegeChildren = _.cloneDeep(fields.ta_college_children)
                        newTaCollegeChildren.push({
                          name: '',
                          grade: '',
                        })
                        this.setStateThenSaveLocal({
                          fields: { ...fields, ta_college_children: newTaCollegeChildren },
                        })
                      }}
                    >
                      Add
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <h5 className="mt-3 col-12">14. List other children in your family NOT attending ICS or university</h5>
            <div className="col-12">
              <div className="d-none d-md-flex row">
                <div className="col-4">Name</div>
                <div className="col-2">Age</div>
                <div className="col-2">{`Grade in ${this.renderYear(true)}`}</div>
                <div className="col-3">School Attending (if any)</div>
                <div className="col-1" />
              </div>
              <div className="row">
                <div className="col-12">
                  {fields.ta_other_children.map((p, idx) => (
                    <div key={idx} className="row">
                      <div className="col-12 col-md-4">{renderFields(['ta_other_children', idx, 'name'], { fieldName: 'Name', labelClassName: 'd-md-none' })}</div>
                      <div className="col-12 col-md-2">{renderFields(['ta_other_children', idx, 'age'], { fieldName: 'Age', labelClassName: 'd-md-none' })}</div>
                      <div className="col-12 col-md-2">{renderFields(['ta_other_children', idx, 'grade'], { fieldName: 'Grade', labelClassName: 'd-md-none' })}</div>
                      <div className="col-12 col-md-3">{renderFields(['ta_other_children', idx, 'school'], { fieldName: 'School', labelClassName: 'd-md-none' })}</div>
                      <div className="col-12 col-md-1">
                        {idx !== 0 ? (
                          <button
                            type="button"
                            className="btn btn-info btn-form"
                            onClick={() => {
                              const newTaOtherChildren = _.cloneDeep(fields.ta_other_children)
                              newTaOtherChildren.splice(idx, 1)
                              this.setStateThenSaveLocal({
                                fields: { ...fields, ta_other_children: newTaOtherChildren },
                              })
                            }}
                          >
                            Delete
                          </button>
                        ) : null}
                        <hr className="d-md-none" />
                      </div>
                    </div>
                  ))}
                  {fields.ta_other_children.length < 6 ? (
                    <button
                      type="button"
                      className="btn btn-info btn-form"
                      onClick={() => {
                        const newTaOtherChildren = _.cloneDeep(fields.ta_other_children)
                        newTaOtherChildren.push({
                          name: '',
                          grade: '',
                        })
                        this.setStateThenSaveLocal({
                          fields: { ...fields, ta_other_children: newTaOtherChildren },
                        })
                      }}
                    >
                      Add
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <h1>Financial Information</h1>
          <b>All amount must be in Thai Baht</b>
          <div className="row mt-4">
            <h5 className="mt-3 col-12">
              1. Total <strong>annual</strong> income in <strong>Thai Baht</strong>
            </h5>
            <div className="col-12">
              <div className="row">
                <h5 className="mt-3 col-4">Source of Income</h5>
                <h5 className="mt-3 col-4 text-center">
                  <div>{`ACTUAL INCOME for 2024`}</div>
                  <div>(Enter numbers only)</div>
                </h5>
                <h5 className="mt-3 col-4 text-center">{`ESTIMATED INCOME for 2025`}</h5>
                <h5 className="col-4">Father's salary/wages/income</h5>
                <div className="col-4">{renderFields(['actual_father_income'])}</div>
                <div className="col-4">{renderFields(['estimated_father_income'])}</div>
                <h5 className="col-4">Mother's salary/wages/income</h5>
                <div className="col-4">{renderFields(['actual_mother_income'])}</div>
                <div className="col-4">{renderFields(['estimated_mother_income'])}</div>
                <h5 className="col-4">Interest and Dividend income</h5>
                <div className="col-4">{renderFields(['actual_interest_income'])}</div>
                <div className="col-4">{renderFields(['estimated_interest_income'])}</div>
                <h5 className="col-4">Rental income</h5>
                <div className="col-4">{renderFields(['actual_rental_income'])}</div>
                <div className="col-4">{renderFields(['estimated_rental_income'])}</div>
                <h5 className="col-4">Other income (explain):{renderFields(['other_income_explain'])}</h5>
                <div className="col-4">{renderFields(['actual_other_income'])}</div>
                <div className="col-4">{renderFields(['estimated_other_income'])}</div>
                <h5 className="col-4">TOTAL</h5>
                <div className="col-4">{renderFields(['actual_total_income'])}</div>
                <div className="col-4">{renderFields(['estimated_total_income'])}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">
              {/* TODO fix dropdown overflow */}
              2. Do you receive income <strong>in addition to your salary above</strong>?
            </h5>
            <div className="col-12 col-md-6 mt-3 mt-md-0">{renderFields(['residence_allowance'], { fieldName: 'Residence, or a housing allowance' })}</div>
            <div className="col-12 col-md-6 d-flex align-items-center">{renderFields(['residence_allowance_only'], { fieldName: 'Check box if it can only be spent on housing?', check: true })}</div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">{renderFields(['vehicle_allowance'], { fieldName: 'Vehicle or vehicle allowance' })}</div>
            <div className="col-12 col-md-6 d-flex align-items-center">{renderFields(['vehicle_allowance_only'], { fieldName: 'Check box if it can only be spent on vehicle', check: true })}</div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">{renderFields(['educational_allowance'], { fieldName: 'Educational assistance' })}</div>
            <div className="col-12 col-md-6 d-flex align-items-center">{renderFields(['educational_allowance_only'], { fieldName: 'Check box if it can only be spent on education', check: true })}</div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">{renderFields(['other_allowance_explain'], { fieldName: 'Other (explain)' })}</div>
            <div className="col-12 col-md-6">{renderFields(['other_allowance'], { fieldName: 'Value of other:' })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">3. List all assets in Thailand:</h5>
            <div className="col-12">{renderFields(['th_savings'], { fieldName: 'Savings (money intended to save, not money left in bank accounts)' })}</div>
            <div className="col-12">{renderFields(['th_investments'], { fieldName: 'Investments:' })}</div>
            <div className="col-12">{renderFields(['th_properties'], { fieldName: 'Properties:' })}</div>
            <div className="col-12 col-md-6">{renderFields(['th_other_explain'], { fieldName: 'Other (explain):' })}</div>
            <div className="col-12 col-md-6">{renderFields(['th_other'], { fieldName: 'Value of other:' })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">4. List all assets in other countries (in Thai Baht):</h5>
            <div className="col-12">{renderFields(['oc_savings_investment'], { fieldName: 'Savings & Investments' })}</div>
            <div className="col-12">{renderFields(['oc_properties'], { fieldName: 'Properties:' })}</div>
            <div className="col-12 col-md-6">{renderFields(['oc_other_explain'], { fieldName: 'Other (explain):' })}</div>
            <div className="col-12 col-md-6">{renderFields(['oc_other'], { fieldName: 'Value of other:' })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">5. List any personal debt?</h5>
            <div className="col-12">{renderFields(['debt_housing'], { fieldName: 'Housing:' })}</div>
            <div className="col-12">{renderFields(['debt_vehicle'], { fieldName: 'Vehicle:' })}</div>
            <div className="col-12">{renderFields(['debt_business'], { fieldName: 'Personal debt for business:' })}</div>
            <div className="col-12 col-md-6">{renderFields(['debt_other_explain'], { fieldName: 'Other (explain):' })}</div>
            <div className="col-12 col-md-6">{renderFields(['debt_other'], { fieldName: 'Value of other:' })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">6. What percentage discount from tuition are you requesting?</h5>
            <div className="col-12 col-md-6">{renderFields(['ta_percentage'], { inputAppend: '%' })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">7. Do you expect to apply for tuition assistance next year?</h5>
            <div className="col-12 col-md-6">{renderFields(['expect_next_year'], { radioChoices: CONST.yes_no_probably })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">8. Payment plan:</h5>
            <div className="col-12">{renderFields(['payment_plan'], { radioChoices: CONST.ta_payment_plan })}</div>
          </div>
          <div className="row">
            <h5 className="mt-3 col-12">9. If we cannot receive the requested level of assistance, we would seek to:</h5>
            <div className="col-12">{renderFields(['denied_fill'], { fieldName: 'Find a way to fund the gap (fundraise, ask/borrow from relatives)', check: true })}</div>
            <div className="col-12">{renderFields(['denied_loan'], { fieldName: 'Take out a loan', check: true })}</div>
            <div className="col-12">{renderFields(['denied_withdraw'], { fieldName: 'Request a withdrawal of our application', check: true })}</div>
            <div className="col-12">{renderFields(['denied_other_explain'], { fieldName: 'Other (explain)' })}</div>
          </div>
          <h1 className="my-3">Personal Data Protection Policy</h1>
          <div className="px-3 py-2 pdpa" style={{ maxHeight: '70vh', overflowY: 'auto', boxShadow: 'inset 0px 0px 3px 0px rgba(0,0,0,0.75)' }}>
            <h2>ICS Policy on the Personal Data Protection Act (PDPA) </h2>
            <h5>Introduction </h5>
            <p> The International Community School (ICS) will, in the process of its operations; collect, process, disclose and use personal data in compliance with the requirements of Thailand's Personal Data Protection Act (PDPA) and according to the guidelines in this policy.</p>
            <h5>The Basics </h5>
            <p>“Personal data” includes any data pertaining to a living natural person that enables the identification of that person, whether directly or indirectly. The PDPA applies to both digital and physical data, and includes information such as phone numbers, addresses, email addresses, or anything that might enable identification of the data subject—the person directly or indirectly linked to the information in question.</p>
            <h5>Types of information collected: </h5>
            <ul>
              <li>Personal details and demographic information (name, contact details, gender, nationality, medical information, passports, national ID, etc. (for students and families as needed) </li>
              <li>Financial information (only when applying for tuition assistance at ICS) </li>
              <li>Normal work history, education history, references, contact and family information of employees </li>
              <li>Responses to surveys </li>
              <li>Text, images, and film from school-related activities </li>
              <li>Inquiries and comments via the ICS website and social media </li>
            </ul>
            <h5>Purpose of data collection: </h5>
            <ul>
              <li>Maintain regular communication channels for communicating student progress and disseminating information regarding school programs </li>
              <li>Administration and intervention in the event of medical or pastoral counseling requirements </li>
              <li>Review strategic plans, academic policies and guidelines </li>
              <li>Management of security and risk </li>
              <li>Provide education services, track progression of students and evaluate student’s suitability for a course </li>
              <li>Regulatory reporting and compliance </li>
              <li>Analysis of information to improve and develop the ICS website </li>
              <li>To ensure student health and safety </li>
              <li>To apply for and maintain legal status in Thailand </li>
              <li>To determine the need for financial assistance </li>
              <li>To make enrollment decisions or for the purpose of assigning placement in certain activities or services </li>
              <li>To supply data to other schools or universities at the request of the student or parents </li>
              <li>To respond to an inquiry via the website or other channels </li>
              <li>To establish the suitability of an employment applicant and application of benefits of the candidate, if hired </li>
            </ul>
            <h5>Confidentiality of Information </h5>
            <p> Authorized personnel within ICS and appointed data intermediaries will be able to access the information you provide to us. We only do so with your consent and will always ensure that your information is used in accordance with the terms of this privacy policy and the Thailand PDPA. Unless required or permitted to do so by law, we will not otherwise share, sell or distribute any of the information you provide to us without your consent.</p>
            <h5>How to contact us </h5>
            <p>
              The confidentiality of your personal data is important to ICS. You have our assurance that if your personal data is collected, used or disclosed for the purposes we have listed above, we will use it only in accordance with the Thailand Personal Data Protection Act (2019) requirements. If you need clarifications, corrections, access to your information, withdrawal of consent for use of your personal data, or other information regarding your personal data kept with ICS, you can
              contact the school office at: +662-338-0777 The following is more in-depth explanation of this policy and the Thailand PDPA
            </p>
            <h5>PDPA Main Roles </h5>
            <p>
              The PDPA lays out three main roles relating to the handling of others’ personal data: the data controller, the data processor, and data protection officer. The data controller is a person or entity with power to make decisions regarding collection, use, and disclosure of personal data. The data processor is a person or entity that collects, uses, or discloses personal data on behalf of, or under the instructions of, the data controller. The data controller carries significant
              liability and obligations, while the processor’s obligations and liabilities are very limited in comparison.
            </p>
            <div className="ml-3">
              <h6>Data controller’s obligations </h6>
              <p>
                Data controllers take principal responsibility for ensuring that ICS staff fulfill all their obligations for handling personal data, including collection, use, and transfer. Their first duty is to ensure that throughout these steps, the personal data remains correct, up-to-date, complete, and not misleading. In terms of security and maintenance, the data controller must implement suitable measures for preventing loss, unauthorized access, alteration, or disclosure of personal
                data. These measures must be reviewed whenever necessary, such as after the implementation of technological developments. The data must be recorded in a form, either written or electronic, that can be inspected by the data subject or an authorized party. When the storage period expires, the personal data is no longer relevant or exceeds the scope of necessity, or the consent is withdrawn. The data controller then is also responsible for seeing that the personal data is erased. At
                ICS, this person, the school superintendent, is inevitably responsible for this, but is assisted by members of the leadership team and various members of the ICS administrative staff.
              </p>
              <h6>Data processors’ obligations </h6>
              <p>
                Data processors are required to strictly comply with the controller’s lawful instructions and orders, and conversely not take action outside those instructions. The data processor must also implement suitable measures for preventing loss or unauthorized access. They must make sure suitable measures for storing personal data and preventing unauthorized access are in place. The data processor must also record processing information. This means maintaining an inventory of the
                collection, transfer, and use of personal data. At ICS, data processors are all of those who imput, access and use the data as listed above and include nurses, finance office staff, the registrar, the admissions staff, IT staff, teachers, web developers, and various other administrative staff.
              </p>
              <h6>Data Protection Officer </h6>
              <p>
                Data controllers or processors with a large amount of personal data will also have to appoint a data protection officer (DPO) to monitor and verify compliance with the PDPA by conducting compliance audits or inspections. The DPO will interact with the regulator if any issues arise. Businesses with a large retail customer base that generates a large volume of personal data will probably already have a DPO in place. The PDPA requires appointment of a DPO if the nature of the data
                controller’s activities consist of collecting, using, and disclosing personal data, or if these activities require monitoring due to the large scale of personal data (the exact scale to be set later by the Personal Data Protection Commission). At ICS we will not have a specific Data Protection Officer, rather this function is also the responsibility of the superintendent.
              </p>
            </div>

            <h5>Collecting consent </h5>
            <p>
              The collector of personal data must either have <b>consent</b> from the data subject or be covered by one of the exemptions detailed below. Consent can be given in writing or in electronic form. A request for consent must be clear and must not be deceptive or cause the data subject to misunderstand. The controller seeking consent must inform the data subject of the purpose of collection; the type of personal data being collected; relevant third parties to whom the data will be
              disclosed; and the period of retention or use. Any changes to this information will require further consent, and consent can be withdrawn at any time.{' '}
            </p>
            <p>
              Some exceptions, such as when the personal information is for educational, research, or statistics collection purposes (provided appropriate personal data protection measures are in place), or when it helps to prevent danger to a person’s life, body, or health. Also, certain contractual obligations do not require further consent. For instance, an agreement to sell goods and deliver them to various locations or email addresses would not need consent for handling each separate
              delivery address or email.
            </p>
            <h5>Data subjects’ rights </h5>
            <p>Under the PDPA, data subjects are accorded a number of rights over their personal data:</p>
            <ol>
              <li>Objection: The right to object to any collection, use, or disclosure of personal data at any time. </li>
              <li>Access: The right to ask a data controller to provide a copy of the data subject’s personal information and disclose where they obtained it. The data controller will now be obligated to disclose, upon request, how they obtained the data subject’s personal data. </li>
              <li>Erasure: The right to ask a controller to anonymize or delete personal information at any time. </li>
              <li>Data portability: The right to obtain the data in commonly used machine-readable format. This right lets a data subject, for example, ask a hospital to transfer all personal data to the subject or to another hospital. </li>
            </ol>
            <h5>ICS PDPA Adherence Statement </h5>
            <p>The below will be located at the conclusion of every form of data collection we have at ICS including but not limited to ICS Student Applications, ICS Teacher Applications, Tuition Assistance Applications, online after school enrichment sign-up forms, online camp/sport/field trip sign-up forms, etc.</p>
          </div>
          <div className="mt-3 col-12 d-flex align-items-center">
            <div className="mx-3">
              {renderFields(['accepted_pdpa'], {
                fieldName: 'By checking this box, you affirm that you have read and understand the ICS Personal Data Protection Policy and hereby agree to submit and grant access to your personal data to ICS based on the guidelines set out for collecting, use of, and retention of that information as well as the rights afforded to you by this policy and the Thailand PDPA.',
                check: true,
                pdpa: true,
              })}
            </div>
          </div>
          <h1 className="my-3">Required Documents</h1>
          {errors.ta_attachment !== undefined && Array.isArray(errors.ta_attachment) && errors.ta_attachment.join('').includes('Invalid pk') ? <div className="col-12 invalid-attachment">Uploaded attachments expired, please re-upload the attachments</div> : null}
          <div className="mt-2">Please provide any additional background that would be useful for the committee to understand your financial need and family situation. Including a separate letter of explanation in English would be helpful.</div>
          <div className="col-12 px-0">{renderFields(['additional_background'], { textarea: true })}</div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('additional_background', '')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('additional_background')}</div>
          </div>
          <hr />
          <div className="mt-2">If you are a missionary, please describe your ministry and mission in Thailand. Include a separate letter if necessary.</div>
          <div className="col-12 px-0">{renderFields(['missionary_description'], { textarea: true })}</div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('missionary_description', '')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('missionary_description')}</div>
          </div>
          <hr />
          <div className="mt-2">Letter verifying employment and salary & benefits from your employer</div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('employment_verification', '')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('employment_verification')}</div>
            {errors.ta_attachment !== undefined && errors.ta_attachment.employment_verification !== undefined ? <div className="col-12 invalid-attachment">{errors.ta_attachment.employment_verification}</div> : null}
          </div>
          <hr />
          <div className="mt-2">Copy of your current visa and work permit</div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('visa_and_permit', '')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('visa_and_permit')}</div>
          </div>
          <hr />
          <div className="mt-2">Copy of current visa for children applying for tuition assistance</div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('children_visa', '')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('children_visa')}</div>
          </div>
          <hr />
          <div className="mt-2">Copy of your bank statements for at least the last 6 months (English translation of headers would be helpful)</div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('bank_statements', '')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('bank_statements')}</div>
          </div>
          <hr />
          <div className="mt-2">
            Additional supporting document
            <ul>
              <li>Tax Forms</li>
              <li>Pay Stubs</li>
              <li>Income Receipts</li>
              <li>Investment Reports</li>
              <li>Property Value Reports</li>
              <li>Others (please list)</li>
            </ul>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('supporting_documents', '')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('supporting_documents')}</div>
          </div>
          <hr />
          <div className="mt-2">
            <p>
              Letter verifying employment and salary & benefits from your Sending Mission Organization. (Must specify whether your sending organization is registered under CCT or EFT in Thailand. The letter must be <b>current</b>)
            </p>
            <p> Name of your church or organization in Thailand plus its location and mailing address.</p>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">{this.renderDropzone('employment_verification_sending_mission_organization', '')}</div>
            <div className="col-12 col-md-6">{this.renderDroppedFiles('employment_verification_sending_mission_organization')}</div>
          </div>
          <h1 className="mt-4">CERTIFICATION AND AGREEMENT</h1>
          <p>A. I certify that the information provided on this form is accurate in all respects. I understand that if any information included on this form is inaccurate or untrue, the ICS administration will immediately discontinue any tuition assistance granted and request return of previously granted funds.</p>
          <p>B. I understand that my employer and other business parties, etc., may be contacted by ICS to verify the information provided on this application. I give my permission to ICS to make these contacts.</p>
          <p>C. I understand that if given tuition assistance, my child is expected to achieve at least a "C" average and contribute positively to the school community.</p>
          <p>
            <b>D. I understand that the decision of the Tuition Assistance Committee in awarding tuition assistance is final. It is not subject to further review and/or explanation. The amount of tuition assistance awarded is to be treated as confidential between ICS and the receiving family.</b>
          </p>
          <p>E. I understand that the approval of tuition assistance for the upcoming school year in no way obligates ICS to grant tuition assistance for future school years.</p>
          <div className="row">
            <div className="col-12 mb-3">
              <b>Please submit when the application and attachments are complete, incomplete applications will not be processed.</b>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
              <b>Date</b>
              {renderFields(['signature_date'], { DP: true })}
            </div>
            <div className="col-12 col-md-6">
              <b>Father Signature - by entering your full name below you are verifying the information on the application is true and correct. This acts in lieu of a physical signature on your application.</b>
              {renderFields(['signature_father'])}
            </div>
            <div className="col-12 col-md-6">
              <b>Guardian Signature - by entering your full name below you are verifying the information on the application is true and correct. This acts in lieu of a physical signature on your application.</b>
              {renderFields(['signature_guardian'])}
            </div>
            <div className="col-12 col-md-6">
              <b>Mother Signature - by entering your full name below you are verifying the information on the application is true and correct. This acts in lieu of a physical signature on your application.</b>
              {renderFields(['signature_mother'])}
            </div>
          </div>
          <div className="row p-4">
            <button className="btn btn-primary" type="button" onClick={this.submit}>
              Submit
            </button>
          </div>
        </form>
      </Layout>
    )
  }
}

export default withContext(TuitionAssistance)
